<template>
  <div>
    <activator
      @search="search"
      placeholder="ابحث في البريد"
    ></activator>
  </div>
</template>

<script>
import Activator from "@/EK-core/components/EK-dialog/activaitor.vue";
export default {
  components: {
    Activator,
  },
  methods: {
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ['name', 'email', 'subject'],
        query,
      });
    },
  },
};
</script>

<style>
</style>